/**
 * Index
 * ================================================== */
.index {
  .sec-main-vis {
    .main-vis-area {
      position: relative;
      .main-vis-sub-title {
        position: absolute;
        bottom: 1.6vw;
        right: 2vw;
        font-size: 10.416666666666667vw;
        line-height: 1;
        font-weight: 700;
        color: $key-color01;
        opacity: 0.82;
        mix-blend-mode: hard-light;
      }
    }
  }
  .sec-what {
    @include base-width();
    margin: 72px auto 0;
    @media screen and (max-width: $medium) {
      margin-top: 40px;
    }
    .what-content {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: -12px -28px;
      @media screen and (max-width: $medium) {
        margin: 0;
      }
      .left-area,
      .right-area {
        flex: none;
        margin: 12px 28px;
        @media screen and (max-width: $medium) {
          margin: 0;
        }
      }
      .left-area {
        width: 450px;
        @media screen and (max-width: $medium) {
          width: 100%;
        }
      }
      .right-area {
        width: 293px;
        @media screen and (max-width: $medium) {
          width: 100%;
          margin-top: 16px;
        }
      }
      .what-title {
        font-size: 30px;
        font-weight: 700;
      }
      .sns-list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 22px -2px;
        @media screen and (max-width: $medium) {
          margin-top: 14px;
          margin-bottom: 14px;
        }
        .list-item {
          margin: 2px;
        }
      }
      .button-area {
        margin-top: 48px;
        @media screen and (max-width: $medium) {
          margin-top: 32px;
        }
      }
    }
  }
  .sec-benefits {
    margin-top: 120px;
    @media screen and (max-width: $medium) {
      margin-top: 64px;
    }
    .com-bg01-contents {
      padding-bottom: 80px;
      @media screen and (max-width: $medium) {
        padding-bottom: 40px;
      }
    }
    .benefits-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      counter-reset: section;
      margin: -8px;
      .list-item {
        width: calc(33.333333% - 16px);
        flex: none;
        position: relative;
        counter-increment: section;
        margin: 8px;
        @media screen and (max-width: $medium) {
          width: calc(50% - 16px);
        }
        .benefits-title-area {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-height: 68px;
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: $key-color01;
            mix-blend-mode: multiply;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
          }
          &::after {
            content: counter(section);
            position: absolute;
            top: 50%;
            left: 20px;
            color: #fff;
            font-family: $din2014;
            font-size: 38px;
            font-weight: 500;
            transform: translate(-50%, -50%);
            @media screen and (max-width: $medium) {
              left: 10px;
              font-size: 16px;
            }
          }
          .benefits-list-title {
            position: relative;
            z-index: 1;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            padding: 8px 4px 8px 40px;
            @media screen and (max-width: $medium) {
              padding: 4px 4px 4px 20px;
              font-size: 14px;
            }
            .smaller {
              font-size: 11px;
              margin-left: 4px;
              @media screen and (max-width: $medium) {
                font-size: 10px;
              }
            }
            br {
              + .smaller {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .sec-voice {
    margin-top: 72px;
    margin-bottom: 120px;
    @media screen and (max-width: $medium) {
      margin-top: 40px;
      margin-bottom: 64px;
    }
    .sec-voice-inner {
      @include base-width();
    }
    .voice-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -8px;
      .list-item {
        width: calc(50% - 16px);
        margin: 8px;
        @media screen and (max-width: $medium) {
          width: calc(100% - 16px);
        }
        iframe {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
        }
      }
    }
  }
  .sec-about {
    .com-bg01-contents {
      padding-top: 96px;
      padding-bottom: 88px;
      @media screen and (max-width: $medium) {
        padding-top: 48px;
        padding-bottom: 40px;
      }
      .about-info-area {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $medium) {
          flex-direction: column;
        }
        .left-area,
        .right-area {
          flex: none;
        }
        .left-area {
          width: 376px;
          max-width: 100%;
        }
        .right-area {
          width: 350px;
          max-width: 100%;
          @media screen and (max-width: $medium) {
            margin-top: 24px;
          }
        }
        .about-title {
          font-family: $din2014;
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 32px;
          @media screen and (max-width: $medium) {
            margin-bottom: 16px;
            font-size: 24px;
          }
        }
        .about-history {
          .list-item {
            font-size: 17px;
            font-weight: 700;
            @media screen and (max-width: $medium) {
              font-size: 14px;
            }
            &:nth-of-type(n+2) {
              margin-top: 1em;
            }
          }
        }
      }
      .about-text {
        margin-top: 40px;
        padding: 48px;
        background-color: $key-color01;
        color: #fff;
        font-size: 14px;
        line-height: 1.8;
        @media screen and (max-width: $medium) {
          margin-top: 24px;
          padding: 24px;
          font-size: 12px;
        }
      }
    }
  }
  .sec-how-to {
    margin-top: 64px;
    .sec-how-to-inner {
      @include base-width();
      margin-bottom: 120px;
      @media screen and (max-width: $medium) {
        margin-bottom: 64px;
      }
      .caution {
        margin-bottom: 64px;
        @media screen and (max-width: $medium) {
          margin-bottom: 48px;
        }
      }
    }
    .how-to-step {
      .list-item {
        &:nth-of-type(n+2) {
          margin-top: 64px;
        }
        .step-title {
          font-size: 20px;
          font-weight: 700;
          color: $key-color01;
          margin-bottom: 16px;
          @media screen and (max-width: $medium) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .step-title-eyecatch {
            font-family: $din2014;
            font-weight: 700;
            font-size: 22px;
            background-color: $bg-color02;
            display: inline-block;
            line-height: 1;
            padding: 2px 8px 0px 16px;
            margin-right: 36px;
            position: relative;
            vertical-align: 4px;
            @media screen and (max-width: $medium) {
              margin-bottom: 8px;
            }
            &::before {
              content: '';
              display: block;
              width: 21px;
              height: 100%;
              background-color: $bg-color02;
              clip-path: polygon(0 0, 1px 0, 100% 100%, 0 100%);
              position: absolute;
              right: 1px;
              top: 0;
              transform: translateX(100%);
            }
            .num {
              font-family: $din2014;
              font-weight: 700;
              font-size: 34px;
              line-height: 1;
              display: inline-block;
              margin-left: 4px;
            }
          }
        }
        .step-contents {
          padding-left: 131px;
          @media screen and (max-width: $medium) {
            padding-left: 0;
          }
          .base-text {
            font-size: 14px;
            line-height: 2;
          }
          .button-area {
            margin-top: 24px;
            text-align: center;
            .com-btn01 {
              display: inline-block;
              width: auto;
            }
          }
        }
      }
    }
    .how-to-img-area {
      .com-bg01-inner {
        position: relative;
        overflow: hidden;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $bg-color02;
          mix-blend-mode: multiply;
          opacity: 0.24;
          z-index: 2;
        }
        .how-to-bg-img {
          position: absolute;
          top: -10%;
          left: 0;
          width: 120%;
          height: 120%;
          object-fit: cover;
          opacity: 0.26;
          transform: rotate(2.792702deg);
          z-index: 1;
        }
      }
      .com-bg01-contents {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 96px;
        position: relative;
        z-index: 1;
        .how-to-img {
          max-width: 680px;
          width: 100%;
        }
      }
    }
  }
  .sec-faq {
    margin-top: 72px;
    margin-bottom: 120px;
    @media screen and (max-width: $medium) {
      margin-top: 48px;
      margin-bottom: 64px;
    }
    .sec-faq-inner {
      @include base-width();
    }
    .faq-list {
      .list-item {
        &:nth-of-type(n+2) {
          margin-top: 64px;
          @media screen and (max-width: $medium) {
            margin-top: 32px;
          }
        }
        .faq-title {
          min-height: 40px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 24px;
          @media screen and (max-width: $medium) {
            align-items: flex-start;
            font-size: 16px;
            margin-bottom: 8px;
          }
          &::before {
            content: 'Q';
            font-family: $din2014;
            font-size: 22px;
            font-weight: 700;
            color: $key-color01;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: $bg-color02;
            margin-right: 16px;
            flex: none;
          }
        }
        .faq-text {
          padding-left: 56px;
          font-size: 14px;
          line-height: 2;
          @media screen and (max-width: $medium) {
            font-size: 13px;
          }
          a {
            font-size: 14px;
            color: blue;
            @media screen and (max-width: $medium) {
              font-size: 13px;
            }
          }
        }
      }
    }
    .message-area {
      margin-top: 64px;
      @media screen and (max-width: $medium) {
        margin-top: 32px;
      }
      .message {
        padding: 16px;
        text-align: center;
        border: 1px solid #000;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 700;
        @media screen and (max-width: $medium) {
          font-size: 13px;
        }
        a {
          color: blue;
          font-size: 15px;
          @media screen and (max-width: $medium) {
            font-size: 13px;
          }
        }
      }
    }
    .button-area {
      margin-top: 64px;
      text-align: center;
      @media screen and (max-width: $medium) {
         margin-top: 32px;
      }
      .com-btn01 {
        display: inline-block;
        width: auto;
        min-width: 293px;
      }
    }
  }
}