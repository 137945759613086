/**
 * Fix
 * ================================================== */
.fix-area {
  position: fixed;
  bottom: 80px;
  right: 0;
  animation: 4s ease-in-out infinite fluffy;
  .admission-contents {
    position: relative;
    display: flex;
    text-decoration: none;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 4px;
      left: 0;
      background-color: $bg-color04;
      clip-path: polygon(40px 0, 100% 0, 100% 100%, 0 100%);
    }
    .admission-contents-inner {
      position: relative;
      padding: 12px;
      padding-left: 48px;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $bg-color05;
        clip-path: polygon(40px 0, 100% 0, 100% 100%, 0 100%);
      }
      .admission-text {
        position: relative;
        z-index: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: $medium) {
          font-size: 14px;
        }
        .bigger {
          font-size: 22px;
          font-weight: 700;
          display: inline-block;
          position: relative;
          @media screen and (max-width: $medium) {
            font-size: 16px;
          }
          .iconify {
            position: absolute;
            top: calc(50% + 2px);
            left: 0;
            transform: translate(-100%, -50%);
          }
        }
      }
    }
  }
}
@keyframes fluffy {
  0% {
    bottom: 80px;
  }
  50% {
    bottom: 60px;
  }
  100% {
    bottom: 80px;
  }
}