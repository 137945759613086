/**
 * Common
 * ================================================== */
.caution {
  text-align: center;
  .caution-inner {
    border: 1px solid $alert-color;
    border-radius: 4px;
    padding: 16px 32px;
    font-size: 15px;
    font-weight: 700;
    color: $alert-color;
    display: inline-block;
    @media screen and (max-width: $medium) {
      padding: 8px 12px;
      font-size: 13px;
    }
  }
}
.com-bg01 {
  background-color: $bg-color01;
  overflow: hidden;
  padding: 32px 0;
  .com-bg01-inner {
    background-color: $bg-color02;
    transform: rotate(-2.792702deg) translateX(-50%);
    transform-origin: 0% 50%;
    width: 120vw;
    margin-left: 50%;
    position: relative;
    @media screen and (max-width: $medium) {
      width: 150vw;
    }
    .com-bg01-message {
      position: absolute;
      top: -.15em;
      left: 50%;
      transform: translateX(-50%);
      color: $bg-font-color01;
      opacity: 0.2;
      font-family: $din2014;
      font-size: 192px;
      font-weight: 500;
      width: 100%;
      text-align: center;
      line-height: 1;
      @media screen and (max-width: $medium) {
        font-size: 52px;
      }
    }
    .com-bg01-contents {
      @include base-width();
      width: 100vw;
      padding-top: 32px;
      padding-bottom: 32px;
      transform: rotate(2.792702deg);
      @media screen and (max-width: $large) {
        transform: rotate(2.792702deg);
      }
    }
  }
}