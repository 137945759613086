/**
 * Footer
 * ================================================== */
.footer {
  .copy {
    background-color: $bg-color01;
    padding: 40px;
    text-align: center;
    font-size: 14px;
    @media screen and (max-width: $medium) {
      padding: 24px;
    }
  }
  .footer-info {
    background-color: $key-color01;
    padding: 48px 40px;
    color: #fff;
    font-size: 17px;
    @media screen and (max-width: $medium) {
      padding: 32px 0;
    }
    .inner {
      @include base-width();
      display: block;
    }
  }
}