/**
 * SNS
 * ================================================== */
.sns-share {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  text-decoration: none;
  font-size: 10px;
  padding: 4px 6px;
  .iconify {
    margin-right: 3px;
    font-size: 13px;
  }
  &.sns-twitter {
    color: #fff;
    background-color: $twitter-color;
  }
  &.sns-facebook {
    color: #fff;
    background-color: $facebook-color;
  }
  &.sns-line {
    color: #fff;
    background-color: $line-color;
  }
}