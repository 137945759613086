/**
 * Util
 * ================================================== */
.pc-none {
  @media screen and (min-width: ($medium + 1)) {
    display: none !important;
  }
}
.sp-none {
  @media screen and (max-width: $medium) {
    display: none !important;
  }
}
@mixin base-width {
  max-width: $base-width + 80;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: $medium) {
    padding-left: 24px;
    padding-right: 24px;
  }
}