/**
 * Setting
 * ================================================== */
// media width
$large: 1440px;
$medium: 768px;

// width
$base-width: 800px;

// fonts
$noto: 'Noto Sans JP', sans-serif;
$din2014: din-2014, sans-serif;

// colors
$key-color01: #f19d38;
$key-color02: #ed6f2e;
$bg-color01: #eaead7;
$bg-color02: #fdf1dd;
$bg-color03: #ffff00;
$bg-color04: #f19e88;
$bg-color05: #eb4826;
$bg-font-color01: #959696;
$alert-color: #ea3323;
$twitter-color: #1DA1F2;
$facebook-color: #4267B2;
$line-color: #00B900;