/**
 * Title
 * ================================================== */
.com-title01 {
  padding-top: 16px;
  color: $key-color01;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1;
  position: relative;
  @media screen and (max-width: $medium) {
    font-size: 32px;
    padding-top: 12px;
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
    max-width: 450px;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width: $medium) {
      width: 50%;
    }
  }
  + .com-title01-sub {
    font-weight: 700;
    font-size: 19px;
    line-height: 1;
    margin-top: 12px;
    margin-bottom: 32px;
    @media screen and (max-width: $medium) {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }
}
.com-title02-wrapper {
  text-align: center;
  margin-bottom: 64px;
  @media screen and (max-width: $medium) {
    margin-bottom: 40px;
  }
}
.com-title02 {
  border: 4px solid $key-color02;
  background-color: #fff;
  color: $key-color02;
  border-radius: 99999px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 8px 32px;
  line-height: 1;
  display: inline-block;
  min-width: 293px;
}