/**
 * Button
 * ================================================== */
.com-btn01 {
  border: 1px solid $key-color01;
  background-color: $bg-color03;
  color: $key-color01;
  font-weight: 700;
  font-size: 15px;
  border-radius: 4px;
  padding: 16px;
  display: block;
  width: 100%;
  transition: all .2s;
  &:hover {
    opacity: 0.7;
  }
}